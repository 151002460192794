import React  from "react";
import { Link } from "gatsby"

// componenents
import Layout from "../components/Layout";

// Todo:
// - 

// markup
const IndexPage = () => {
  return (
    <Layout>
      <h3>Email verification complete</h3>

      You can continue to the main menu: <Link to=''>Main menu</Link>
    </Layout>
  )
};

export default IndexPage;